.single-toy-container {
  .toy {
    background: $color-white;
    padding: rem(25) rem(25);
    display: flex;
    justify-content: space-between;

    @include tablet-down {
      display: block;
    }

    &-left {
      flex: 0 0 48.5%;

      .toy-back {
        a {
          display: inline-block;
          padding: rem(8) rem(15);
          border: 1px solid $color-black;
          background: $color-orange;
          text-transform: uppercase;
          color: $color-black;
          font-family: $secondary-font;
          font-size: rem(32);
          font-weight: 700;
        }
      }

      .toy-gallery {
        &__main {
          img {

          }
        }

        &__images {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .item {
            flex: 0 0 15%;
            margin: 0 0.8% 0.8%;
            border: 1px solid #d9d9d9;
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
            padding: rem(8);
          }
        }
      }
    }

    &-right {
      flex: 0 0 48.5%;

      @include tablet-down {
        padding-top: 30px;
      }

      .toy-title {
        font-size: rem(32);
        text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.9);
        line-height: 1.25;
        font-family: $secondary-font;
        font-weight: 700;
        margin: rem(15) 0;
      }

      .toy-desc {
        line-height: 1.3;
        color: #454545;

        p {
          margin: 0 0 1rem 0;
        }
      }

      .toy-features {
        &__title {
          font-size: rem(32);
          line-height: 1.25;
          font-family: $secondary-font;
          font-weight: 700;
          margin: rem(15) 0;
        }

        &__content {
          line-height: 1.3;
          color: #454545;

          p {
            margin: 0 0 1rem 0;
          }
        }
      }
    }
  }
}