.felds-tickets-block {
  --ticket-bg-color: #222428;
  padding-bottom: rem(50);

  &.felds-tickets-block-mx {
    --ticket-bg-color: #002f65;
  }

  .block-title {
    font-family: $secondary-font;
    font-weight: 700;
    font-size: rem(35.2);
    line-height: 1.1;
    letter-spacing: -0.01em;
    text-align: center;
    text-transform: uppercase;
    padding: 0 0 1em;
    color: var(--ticket-bg-color);

    span {
      color: var(--ticket-color);
    }
  }

  .block-content {
    .tickets {
      .ticket {
        display: flex;
        justify-content: space-between;
        padding: rem(25) rem(60);
        position: relative;
        color: $color-white;

        @include tablet-down {
          background: var(--ticket-bg-color);
          display: block;
        }

        @include phone-down {
          padding: 20px 15px;
        }

        & + .ticket {
          margin-top: 2px;
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--ticket-bg-color);
          transform: skewX(-20deg);
          left: 0;
          top: 0;

          @include tablet-down {
            display: none;
          }
        }

        & > div {
          position: relative;
        }

        &-info {
          display: flex;
          flex-wrap: wrap;
          flex-grow: 1;

          @include phone-down {
            display: block;
          }

          &__title {
            flex: 0 0 100%;
            display: flex;
            font-size: rem(28.8);
            font-weight: 700;
            font-family: $secondary-font;
            padding-bottom: rem(10);

            @include phone-down {
              display: block;
              font-size: 20px;
              text-align: center;
            }

            div {
              &:first-child {
                color: var(--ticket-color);
              }

              & + div {
                &:before {
                  content: '/';
                  padding: 0 rem(8);

                  @include phone-down {
                    display: none;
                  }
                }
              }
            }
          }

          &__venue {
            font-family: $secondary-font;
            font-weight: 700;
            letter-spacing: -0.1px;
            line-height: 1.5;
            flex: 0 0 40%;

            @include phone-down {
              text-align: center;
            }
          }

          &__schedules {
            flex: 0 0 60%;
            font-family: $secondary-font;
            font-weight: 500;
            letter-spacing: -0.1px;
            line-height: 1.3;

            @include phone-down {
              padding: 15px 0 0 0;
            }

            table {
              width: 95%;

              thead {
                th {
                  color: var(--ticket-color);
                  font-size: rem(17.6);
                  text-align: left;
                  text-transform: uppercase;
                  width: 33%;
                }
              }

              tbody {
                tr {
                  td {
                    padding: 3px 3px;
                    white-space: nowrap;

                    a {
                      & + a {
                        margin-left: 15px;
                      }
                    }

                    img {
                      max-height: 15px;
                      width: auto;
                      display: inline-block;
                      transition: all 250ms linear;

                      &:hover {
                        transform: scale(1.1);
                      }
                    }
                  }
                }
              }
            }
          }

          &__classes {
            flex: 0 0 100%;
            display: flex;
            padding-top: rem(10);
            font-family: $secondary-font;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: -0.1px;
            line-height: 1.5;
            color: var(--ticket-color);

            div {
              & + div {
                &:before {
                  content: '/';
                  padding: 0 rem(6);
                  color: $color-white;
                }
              }
            }
          }
        }

        &-links {
          @include tablet-down {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
          }

          a {
            display: block;
            min-width: 170px;
            text-align: center;
            padding: rem(10) rem(15);
            font-size: rem(19.2);
            font-weight: 700;
            font-family: $secondary-font;
            background: $color-white;
            color: $color-gray;
            text-transform: uppercase;
            text-decoration: none;

            @include tablet-down {
              flex: 0 0 48.5%;
            }

            @include phone-down {
              min-width: auto;
            }

            & + a {
              margin-top: 10px;

              @include tablet-down {
                margin-top: 0;
              }
            }

            &:hover {
              color: $color-white;
              background: var(--ticket-color);
              opacity: 0.9;
            }

            &.link-view {
              background: var(--ticket-color);
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.felds-tickets-block-xc {
  --ticket-bg-color: #190D40 !important;
  .block-content .tickets .ticket-links a.link-view{
    color: #190D40;
  }
}

.felds-tickets-block{
  .block-content{
    .tickets{
      .ticket{
        &:first-child{
          background-color: var(--ticket-bg-color);
          flex-direction: column;
          gap: 15px;
          padding: 30px 30px 30px 750px;
          height: 600px;
          margin-bottom: 30px;
          &::before,&::after{
            display: none;
          }
          &::before{
            display: block;
            top: 0;
            left: 0;
            position: absolute;
            background-color: silver;
            width: 750px;
            height: 100%;
            transform: none;
            background-image: url('../../dist/img/ticket-image.jpg');
            background-size: cover;
          }
          .ticket-info,.ticket-links{
            padding-left: 30px;
          }
          .ticket-info{
            gap:  5px;
            .ticket-info__title{
              flex-direction: column;
              div{
                &::before{
                  display: none;
                }
                &:nth-child(2){
                  font-size: 52px;
                }
                &:last-child{
                  font-size: 52px;
                }
              }
            }
          }
          .ticket-links{
            display: flex;
            flex-direction: row;
            gap: 30px;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            a{
              flex: 1;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1090px) {
    .block-content{
      .tickets{
        .ticket{
          &:first-child{
            padding: 330px 30px 40px 30px;
            height: unset;
            &::before{
              width: 100%;
              height: 300px;
            }
          }
        }
      }
    }
  }
}
