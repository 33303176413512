.felds-standings-block {
  background: $color-white;
  overflow: hidden;

  .block-title {
    position: relative;

    &:before {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      background: $color-gray;
    }

    h2 {
      position: relative;
      color: $color-white;
      text-align: center;
      font-style: italic;
      font-family: 'Dinbold';
      font-weight: 700;
      font-size: rem(56);
      padding: rem(15) rem(20);
      margin: 0;

      @include tablet-down {
        font-size: 40px;
      }

      @include phone-down {
        font-size: 30px;
      }
    }
  }

  .block-content {
    padding: rem(20) 0;

    .standings {
      &-header {
        display: flex;

        @include phone-down {
          display: none;
        }

        div {
          font-size: 11px;
          font-family: 'Dinbold';
          font-weight: 700;
          font-style: italic;
          text-transform: uppercase;
          text-align: center;

          & + div {
            margin-left: rem(24);

            @include tablet-down {
              margin-left: 15px;
            }
          }
        }

        .header-place {
          width: 80px;

          @include tablet-down {
            width: 56px;
          }
        }

        .header-name {
          flex-grow: 1;
        }

        .header-brand {
          width: 112px;

          @include tablet-down {
            width: 72px;
          }
        }

        .header-points {
          width: 160px;

          @include tablet-down {
            width: 96px;
          }
        }

        .header-difference {
          width: 112px;

          @include tablet-down {
            width: 72px;
          }
        }
      }

      &-content {
        .item {
          margin-top: 15px;
          font-family: $secondary-font;
          font-size: rem(60);
          font-style: italic;
          font-weight: 700;
          text-align: center;
          transition: all .4s ease-in-out;
          line-height: 80px;

          @include tablet-down {
            font-size: 30px;
            line-height: 60px;
          }

          @include phone-down {
            font-size: 16px;
            line-height: 44px;
          }

          a {
            display: flex;
            text-decoration: none;
            color: $color-black;

            &:hover {
              color: $color-blue;
            }

            > div {
              padding-right: 8px;
              box-sizing: border-box;
  
              & + div {
                margin-left: rem(24);

                @include tablet-down {
                  margin-left: 15px;
                }

                @include phone-down {
                  margin-left: 8px;
                }
              }
            }
          } 
          
  
          &-place {
            width: 80px;
            font-family: 'Dinbold';

            @include tablet-down {
              width: 56px;
            }

            @include phone-down {
              width: 30px;
              padding-right: 0;
            }
          }
  
          &-name {
            flex-grow: 1;
            display: flex;

            &:before {
              border-radius: 100px 0 0 300px;
            }

            &__photo {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              overflow: hidden;
              background: linear-gradient(0deg,#f2f2f2 0,#fff 100%);

              @include tablet-down {
                width: 60px;
                height: 60px;
              }

              @include phone-down {
                width: 44px;
                height: 44px;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            &__country {
              margin-left: -14px;

              img {
                max-width: 28px;
                max-height: 20px;
                box-shadow: 0 2px 5px rgba(0,0,0,.2);
                filter: saturate(1.5);
                margin-top: 52px;

                @include tablet-down {
                  margin-top: 36px;
                }

                @include phone-down {
                  margin-top: 28px;
                  max-width: 15px;
                }
              }
            }

            &__full {
              margin-left: 16px;
              letter-spacing: -0.5px;
              white-space: nowrap;
              text-transform: uppercase;

              @include phone-down {
                margin-left: 10px;
              }
            }
          }
  
          &-brand {
            width: 112px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include tablet-down {
              width: 72px;
            }

            @include phone-down {
              width: 40px;
            }

            img {
              max-width: 80%;
              max-height: 64px;
              width: auto;
            }
          }
  
          &-points {
            width: 160px;
            color: $color-blue;

            @include tablet-down {
              width: 96px;
            }

            @include phone-down {
              width: 40px;
            }
          }
  
          &-difference {
            width: 112px;
            color: $color-blue;

            @include tablet-down {
              width: 72px;
            }

            @include phone-down {
              width: 40px;
            }
          }

          &-name,
          &-brand,
          &-points,
          &-difference {
            position: relative;
            transition: all .4s ease-in-out;

            > * {
              position: relative;
            }

            &:before {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: -webkit-gradient(linear,left bottom,left top,from(#f2f2f2),to(white));
              background: linear-gradient(0deg,#f2f2f2 0,#fff 100%);
              content: " ";
              box-shadow: 0 2px 5px rgba(0,0,0,.18);
              transform: skew(-24deg);
              transition: all .4s ease-in-out;
            }
          }

          &:hover {
            transform: scale(1.025) translate3d(0,-1px,0);

            .item-name,
            .item-brand,
            .item-points,
            .item-difference {
              &:before {
                box-shadow: 0 3px 9px rgba(0,0,0,.24);
              }              
            }

            .item-name {
              color: $color-blue;
            }
          }
        }
      }
    }

    .cta-link {
      text-align: center;
      padding: 25px 0 10px 0;
      
      a {
        font-size: rem(20);
        font-family: 'Dinbold';
        letter-spacing: 1px;

        @include phone-down {
          font-size: 16px;
        }

        &:hover {
          color: $color-blue;
        }
      }
    }
  }
}