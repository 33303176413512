.felds-results-block {
  padding: rem(50) 0;

  .container {
    max-width: 960px;
  }

  .block-title {
    font-family: $secondary-font;
    font-weight: 700;
    font-size: rem(20);
    text-align: center;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    padding: 10px 0;
  }

  .block-content {
    padding-top: rem(55);

    table {
      width: 100%;
      border: 1px solid #ddd;

      thead {
        th {
          border: 1px solid $color-gray;
          background: $color-gray;
          color: $color-white;
          padding: rem(15);
          font-size: rem(18);
          font-weight: 700;
          text-align: left;
        }
      }
    
      tbody {
        tr {
          td {
            border-bottom: 1px solid $color-gray;
            padding: rem(20) rem(15);
            background: $color-white;
            color: $color-black;
            text-align: left;
            font-size: 14px;
            line-height: 1.4;
    
            a {
              text-decoration: none;
              color: $color-blue;
            }
          }
        }
      }
    }

    .mobile-table-data {
      table {
        border: 2px solid $color-black;

        & + table {
          margin-top: 15px;
        }

        tr {
          &:first-child {
            td {
              &:last-child {
                font-size: 20px;
              }
            }
          }

          td {
            border-bottom: 2px solid $color-black;

            &:first-child {
              text-transform: uppercase;
              font-size: 20px;
              font-family: $secondary-font;
              font-weight: 700;
            }

            &:last-child {
              font-size: 13px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
