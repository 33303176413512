.felds-toys-block {
  padding: rem(50) 0;

  .block-filter {
    padding: 0 0 rem(50) 0;

    .filter-field {
      width: 100%;
      max-width: 400px;
      display: flex;
      align-items: center;

      input {
        width: rem(230);
        height: rem(36);
        padding: rem(6);
        font-size: 14px;
        line-height: 1;
        border: 1px solid #cfd4d8;
        background: $color-white;
        margin: 0 10px;
      }

      button {
        background: $color-black;
        border: none;
        border-radius: 3px;
        color: $color-white;
        font-weight: bold;
        height: rem(36);
        padding: 0 20px;
      }
    }
  }

  .block-content {
    display: flex;
    flex-wrap: wrap;

    @include phone-down {
      display: block;
    }
    
    .item {
      flex: 0 0 23%;
      margin: 0 1% 2%;
      background: $color-white;
      padding: rem(20);
      box-shadow: 0px 2px 8px 2px #e3e3e3;
      border-radius: rem(10);

      @include tablet-down {
        flex: 0 0 31%;
      }
      
      &-image {
        position: relative;
        
        img {
          width: 100%;
          height: auto;
        }
      }

      &-title {
        font-family: $secondary-font;
        font-size: rem(20);
        font-weight: 700;
        text-align: center;
        line-height: 1.25;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        padding: 0 rem(20);
        margin: rem(10) 0;
      }

      &-action {
        text-align: center;

        span {
          display: inline-block;
          border: 1px solid $color-black;
          border-radius: rem(40);
          padding: rem(8) rem(15);
          font-size: rem(14);
          font-family: Arial;
          text-transform: uppercase;
        }
      }
    }
  }

  .block-action {
    text-align: center;
    padding: rem(30) 0 0 0;
    
    .wp-pagenavi {
      display: inline-flex;
      background: $color-white;
      font-size: rem(16);

      span {
        border: none;
        padding: .8rem 1.4rem;
        background: $color-skin;
        font-weight: 400;

        &.current {
          background: $color-blue;
        }
      }

      a {
        padding: .8rem 1.4rem;
        border: none;
        background: $color-skin;
      }
    }
  }
}
