.felds-latest-posts-block {
  padding: rem(50) 0;

  .block-filter {
    padding: 0 0 rem(50) 0;

    .filter-field {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;      

      select {
        width: 100%;  
      }
    }
  }

  .block-content {
    .latest-posts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        flex: 0 0 31.8%;
        margin-bottom: rem(40);
        position: relative;
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.05), 0 3px 10px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        transition: all .3s ease-in-out;

        &:hover {
          transform: scale(1.02);
        }

        @include tablet-down {
          flex: 0 0 100% !important;
        }

        &.item-advert {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        &-image {
          a {
            display: block;

            img {
              width: 100%;
            }
          }
        }

        &-content {
          flex-grow: 1;
          padding: rem(20);
          background: $color-white;
          display: flex;
          flex-direction: column;

          &__cat {
            span {
              font-size: 1rem;
              line-height: 1.8;
              font-weight: 700;
              color: $color-blue;
              text-transform: uppercase;
              font-family: $secondary-font;

              & + span {
                margin-left: rem(10);
              }
            }
          }

          &__title {
            padding: rem(5) 0 rem(15);
            flex-grow: 1;

            a {
              font-family: $secondary-font;
              font-size: rem(28);
              font-weight: 700;
              line-height: 1;
              color: $color-black;

              &:hover {
                color: $color-blue;
              }
            }
          }

          &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-date {
              color: $color-gray;
              font-size: rem(14);
              line-height: 1.3;
            }

            &-more {
              a {
                color: $color-blue;
                font-size: rem(14);
                line-height: 1.3;
                font-weight: 600;

                &:hover {
                  text-decoration: underline;
                }

                i {
                  font-size: rem(12);
                }
              }
            }
          }
        }

        &:nth-child(1) {
          flex: 0 0 100%;

          .item-image {
            position: relative;

            &:after {
              opacity: 0.66;
              width: 100%;
              height: 100%;
              max-height: 180px;
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(0deg,rgba(0,0,0,.8548012955182073) 15%,rgba(0,0,0,0) 100%);
              content: '';
            }
          }

          .item-content {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background: none;

            &__cat {
              span {
                color: $color-white;
              }
            }

            &__title {
              a {
                color: $color-white;
              }
            }

            &__info {
              justify-content: flex-start;

              &-date {
                color: $color-white;
              }

              &-more {
                margin-left: rem(30);

                a {
                  color: $color-white;
                  font-weight: 400;
                }                
              }
            }
          }
        }

        &:nth-child(2) {
          flex: 0 0 48.5%;
        }

        &:nth-child(3) {
          flex: 0 0 48.5%;
        }
      }
    }
  }

  .block-action {
    text-align: center;
    padding: rem(30) 0 0 0;
    
    .btn {
      letter-spacing: 0;
      font-size: rem(24);
      line-height: 2.1;
      font-weight: 400;
      position: relative;
      background: none;
      border: none;
      padding: 0 rem(50);

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: skew(-19deg);
        background: $color-blue;
        content: '';
      }

      span {
        position: relative;
        color: $color-white;
      }
    }

    .wp-pagenavi {
      display: inline-flex;
      background: $color-white;
      font-size: rem(16);

      span {
        border: none;
        padding: .8rem 1.4rem;
        background: $color-skin;
        font-weight: 400;

        &.current {
          background: $color-blue;
        }
      }

      a {
        padding: .8rem 1.4rem;
        border: none;
        background: $color-skin;
      }
    }
  }
}
