.felds-events-block {
  background: $color-black;
  padding: 0 1rem;
  max-height: 210px;
  overflow: hidden;

  @include tablet-down {
    padding: 0;
  }

  .block-content {
    padding: 0 2%;

    @include tablet-down {
      padding: 0;
    }

    .events {
      .event {
        text-align: center;
        color: $color-white;
        line-height: 1.25;
        padding: 10px 0;
        width: 30%;

        @include tablet-down {
          width: 100%;
          padding: 20px 0;
          font-size: 16px;
        }

        &:after {
          background-color: $color-gray;
          position: absolute;
          top: 0px;
          bottom: 0;
          left: -14%;
          width: 5px;
          height: 300%;
          content: '';
          transform: skew(-19deg);

          @include tablet-down {
            display: none;
          }
        }

        &-date {
          font-family: $tertiary-font;
          text-transform: uppercase;
          font-size: 37px;
          
          &__month {
            color: var(--event-color);
          }

          &__day {
            
          }
        }

        &-note {
          color: var(--event-color);
          font-family: $tertiary-font;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }

        &-round {
          font-weight: 700;
          max-width: 200px;
          margin: 0 auto;
        }

        &-location {
          font-size: 1.4em;
          font-weight: 700;
        }

        &-venue {
          
        }

        &-links {
          a {
            line-height: 2;
            font-weight: 700;
            text-transform: uppercase;

            &.link-tickets {
              color: var(--event-color);

              &:after {
                padding-right: 0.35em;
                content: ' /';
                color: $color-gray;
              }
            }

            &.link-details {
              
            }

            &.link-results {
              color: var(--event-color);
            }
          }
        }
      }

      .flickity-button {
        background: none;

        @include phone-down {
          width: 32px;
          height: 32px;
        }

        &:disabled {
          opacity: 0.3;
        }

        &:focus {
          box-shadow: none;
        }

        svg {
          path {
            fill: $color-blue;
          }
        }

        &.next {
          @include tablet-down {
            &:before {
              content: '';
              position: absolute;
              background: $color-gray;
              width: 200%;
              height: 100%;
              transform: skewX(-19deg);
              left: -10px;
              top: 0;
            }
          }

          @include phone-down {
            &:before {
              left: -5px;
            }
          }
        }

        &.previous {
          @include tablet-down {
            &:before {
              content: '';
              position: absolute;
              background: $color-gray;
              width: 200%;
              height: 100%;
              transform: skewX(-19deg);
              right: -10px;
              top: 0;
            }
          }

          @include phone-down {
            &:before {
              right: -5px;
            }
          }
        }
      }
    }
  }
}