.felds-button-block {
  position: relative;
  width: var(--width);
  margin: 0 auto;

  @include phone-down {
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-color);
  }

  &.felds-button-skew {
    &:before {
      left: 50%;
      width: 97%;
      transform: skewX(-19deg) translateX(-50%);
    }
  }

  a {
    display: block;
    position: relative;
    color: var(--text-color) !important;
    padding: 0 25px;
    font-size: 24px;
    line-height: 2;
    font-family: $secondary-font;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
  }
}