.rider-photos-container {
  padding: rem(50) 0;
  
  .page-header {
    width: calc(100% - 16rem);
    max-width: 1140px;
    margin: 0 auto rem(50);
    position: relative;
    text-align: center;
    padding: rem(30) 0;

    &:before {
      position: absolute;
      width: 110%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: skewX(-19deg) translateX(-50%);
      content: '';
      background: $color-gray;
    }

    h2 {
      position: relative;
      color: $color-blue;
      text-transform: uppercase;
      font-family: $secondary-font;
      font-size: rem(40);
      line-height: 1.25;
      margin: 0;
    }
  }
}