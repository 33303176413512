.felds-livetiming-block {
  padding: 80px 0;
  overflow: hidden;

  > .container {
    max-width: 1370px;
  }

  .livetiming-results-wrapper {
    display: none;
  }

  .livetiming-results {
    display: flex;
    justify-content: space-between;

    @include phone-down {
      display: block;
    }

    .results-block {
      flex: 0 0 48%;

      .block-title {
        position: relative;
        background: $color-gray;
    
        h2 {
          position: relative;
          color: $color-white;
          text-align: center;
          font-style: italic;
          font-family: 'Dinbold';
          font-weight: 700;
          padding: rem(15) rem(20);
          margin: 0;
          font-size: rem(34);
          letter-spacing: -.025em;
          text-transform: uppercase;
        }
      }

      .block-content {
        padding: rem(20) 0 rem(20) 0;
    
        .livetiming {
          &-header {
            display: flex;
            align-items: center;
    
            div {
              font-size: 11px;
              font-family: 'Dinbold';
              font-weight: 700;
              font-style: italic;
              text-transform: uppercase;
              text-align: center;
    
              & + div {
                margin-left: rem(10);
              }
            }
    
            .header-place {
              width: 24px;
            }
    
            .header-name {
              flex-grow: 1;
            }
    
            .header-brand {
              width: 80px;
            }
    
            .header-points {
              width: 60px;
            }
    
            .header-difference {
              width: 60px;
            }
          }
    
          &-content {
            padding-right: rem(15);

            .item {
              margin-top: 15px;
              font-family: $secondary-font;
              font-size: rem(28);
              line-height: 60px;
              font-style: italic;
              font-weight: 700;
              text-align: center;
              transition: all .4s ease-in-out;
    
              a {
                display: flex;
                text-decoration: none;
                color: $color-black;
    
                &:hover {
                  color: $color-blue;
                }
    
                > div {
                  padding-right: 8px;
                  box-sizing: border-box;
      
                  & + div {
                    margin-left: rem(10);
                  }
                }
              } 
              
      
              &-place {
                width: 24px;
                font-family: 'Dinbold';
              }
      
              &-name {
                flex-grow: 1;
                display: flex;
    
                &:before {
                  border-radius: 100px 0 0 300px;
                }
    
                &__photo {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  overflow: hidden;
                  background: linear-gradient(0deg,#f2f2f2 0,#fff 100%);
    
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
    
                &__country {
                  margin-left: -14px;
    
                  img {
                    max-width: 20px;
                    max-height: 20px;
                    box-shadow: 0 2px 5px rgba(0,0,0,.2);
                    filter: saturate(1.5);
                    margin-top: 36px;
                  }
                }
    
                &__full {
                  margin-left: 16px;
                  letter-spacing: -0.5px;
                  white-space: nowrap;
                  text-transform: uppercase;
                }
              }
      
              &-brand {
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 8px;
    
                img {
                  max-width: 70%;
                  max-height: 48px;
                  width: auto;
                }
              }
      
              &-points {
                width: 60px;
                color: $color-blue;
              }
      
              &-difference {
                width: 60px;
                color: $color-blue;
              }
    
              &-name,
              &-brand,
              &-points,
              &-difference {
                position: relative;
                transition: all .4s ease-in-out;
    
                > * {
                  position: relative;
                }
    
                &:before {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background: -webkit-gradient(linear,left bottom,left top,from(#f2f2f2),to(white));
                  background: linear-gradient(0deg,#f2f2f2 0,#fff 100%);
                  content: " ";
                  box-shadow: 0 2px 5px rgba(0,0,0,.18);
                  transform: skew(-24deg);
                  transition: all .4s ease-in-out;
                }
              }
    
              &:hover {
                transform: scale(1.025) translate3d(0,-1px,0);
    
                .item-name,
                .item-brand,
                .item-points,
                .item-difference {
                  &:before {
                    box-shadow: 0 3px 9px rgba(0,0,0,.24);
                  }              
                }
    
                .item-name {
                  color: $color-blue;
                }
              }
            }
          }
        }
    
        .cta-link {
          text-align: center;
          padding: 25px 0 10px 0;
          
          a {
            font-size: rem(16);
            font-family: $secondary-font;
            color: $color-white;
            background: $color-black;
            display: block;
            padding: 10px 0;
          }
        }
      }
    }
  }

  .standings-link {
    padding: 50px 0;
  }

  .event-results {
    .pdfs-list-table {
      max-width: 970px;
      margin: rem(50) auto;
      width: 100%;

      thead {
        tr {
          th {
            background: $color-gray;
            color: $color-white;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            padding: rem(20);
          }
        }
      }

      tbody {
        tr {
          td {
            background: $color-white;
            text-align: center;
            text-transform: uppercase;
            padding: rem(20);
            border: 0.8px solid $color-skin;
            border-bottom: 1px solid #222428;

            a {
              color: $color-blue;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  
}