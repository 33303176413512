.felds-faq-block {
  padding: rem(50) 0;
  max-width: 1040px;
  margin: 0 auto;
  border-bottom: 1px solid $color-gray;

  .block-title {
    font-family: $secondary-font;
    letter-spacing: 1px;
    font-size: rem(50);
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    padding: 0 0 rem(30) 0;
    text-transform: uppercase;

    @include tablet-down {
      font-size: 30px;
    }

    @include phone-down {
      font-size: 20px;
    }
  }

  .block-content {
    .item {
      & + .item {
        margin-top: 10px;
      }

      &-title {
        padding: rem(5) rem(30) rem(5) 0;
        font-family: $secondary-font;
        font-size: rem(32);
        font-weight: 700;
        line-height: 1.3;
        cursor: pointer;
        position: relative;

        @include tablet-down {
          font-size: 24px;
        }
    
        @include phone-down {
          font-size: 16px;
        }

        &:after {
          content: '\f107';
          font-family: 'Font Awesome\ 6 Pro';
          position: absolute;
          right: 0;
          top: 24px;
          transform: translate(0, -50%);
          font-size: rem(18);
          font-weight: 700;
          transition: all 250ms linear;
          line-height: rem(16);
          text-align: center;

          @include tablet-down {
            top: 16px;
          }

          @include phone-down {
            top: 12px;
          }
        }
      }

      &-content {
        display: none;
        line-height: 1.35;
        padding: rem(20) 0;
        font-size: rem(18);

        @include tablet-down {
          font-size: 16px;
        }

        @include phone-down {
          font-size: 13px;
        }

        p {
          margin: 0;

          & + p {
            margin-top: 15px;
          }
        }

        a {
          text-decoration: underline;
          font-weight: 400;
          color: $color-blue;
        }
      }

      &.opened {
        .item-title {
          &:after {
            content: '\f106';
          }
        }
      }
    }
  }
}
