.felds-logos-block {
  padding: rem(45) 0;
  background: $color-black;

  .block-content {
    @include phone-down {
      font-size: 8px;
    }

    .logos-row {
      display: grid;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      padding: 1em;

      a {
        padding: 0;
        
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      &.large {
        a {
          img {
            max-height: 180px;
          }
        }
      }

      &.medium {
        a {
          img {
            max-height: 100px;
          }
        }
      }

      &.small {
        a {
          img {
            max-height: 50px;
          }
        }
      }
    }
  }
}