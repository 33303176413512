$fa-font-path: "../font/webfonts";
$font-path: "../font/";
$html-font-size: 16;

$color-white: #fff;
$color-black: #000;
$color-gray: #222428;
$color-gray-l: #7e7e7e;
$color-blue-l: #008dff;
$color-blue: #0072ce;
$color-blue-d: #102d6a;
$color-yellow: #ceff00;
$color-orange: #ffc800;
$color-green: #92e601;
$color-skin: #f2f2f2;
$color-skin-d: #e3e2e1;
$color-pink: #D11947;

$primary-font: 'Gotham', sans-serif;
$secondary-font: 'Dincond', sans-serif;
$tertiary-font: 'PopWarner', sans-serif;