.felds-teams-block {
  padding: rem(50) 0;

  @include tablet-down {
    padding-top: 0;

    > .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .block-header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 rem(20) 0;

    .team-summary {
      flex: 0 0 48.5%;
      background: $color-blue-d;
      color: $color-white;
      transform: skewX(-19deg);

      @include tablet-down {
        transform: none;
        flex: 0 0 50%;

        &:first-child {
          border-right: 5px solid $color-white;
        }
      }

      @include phone-down {
        &:first-child {
          border-right: 2px solid $color-white;
        }
      }

      &__title {
        font-family: 'Dinbold';
        text-transform: uppercase;
        text-align: center;
        padding: rem(30) rem(20);
        transform: skewX(19deg);

        @include tablet-down {
          transform: none;
          font-style: italic;
        }

        h3 {
          font-size: rem(56);
          font-style: italic;
          margin: 0;

          @include phone-down {
            font-size: 24px;
          }
        }

        .stats {
          display: flex;
          justify-content: center;

          @include phone-down {
            padding-top: 10px;
          }

          &-item {
            font-weight: 700;
            font-style: italic;

            @include phone-down {
              font-size: 12px;
            }

            & + .stats-item {
              margin-left: rem(20);
              padding-left: rem(20);
              border-left: 1px solid rgba(255, 255, 255, .15);
            }
          }
        }
      }

      &__actions {
        display: flex;
        height: rem(64);
        border-top: 1px solid $color-blue;

        @include phone-down {
          display: block;
          height: auto;
        }

        a {
          flex: 0 0 50%;
          text-align: center;
          font-family: 'Dinbold';
          font-weight: 700;
          font-size: rem(20);
          line-height: 1.25;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color-black;
          text-transform: uppercase;
          letter-spacing: rem(1);

          @include tablet-down {
            font-size: 20px;
            letter-spacing: 0;
          }

          @include phone-down {
            font-size: 13px;
            padding: 10px 0;
            border-top: 2px solid $color-white;
          }

          span {
            transform: skewX(19deg);

            @include tablet-down {
              transform: none;
            }
          }
          
          &:first-child {
            background: $color-blue;
          }
        }
      }
    }
  }

  .block-content {
    .teams {
      & + .teams {
        margin-top: rem(40);
      }

      &-header {
        font-family: 'Dinbold';
        font-size: rem(56);
        font-weight: 700;
        color: $color-black;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        padding: rem(50) 0 rem(20);

        @include tablet-down {
          font-size: 40px;
        }
      }

      &-content {
        .team {
          & + .team {
            margin-top: rem(30);
          }

          &-header {
            position: relative;
            font-size: rem(22);
            line-height: 1.6;
            font-weight: 700;
            letter-spacing: rem(1);
            text-transform: uppercase;
            text-shadow: 0 0 5px rgba(0, 0, 0, .1);
            background: #66cc33;
            color: $color-white;
            width: 90%;
            transform: skew(-24deg);

            @include tablet-down {
              padding: 10px 0 10px 20px;
              line-height: 1.1;
            }

            @include phone-down {
              font-size: 16px;
            }

            &:before {
              background-color: inherit;
              content: '';
              position: absolute;
              top: 0;
              left: -100%;
              height: 100%;
              width: 200%;
              display: block;
            }

            span {
              transform: skew(24deg);
              display: block;
            }
          }

          &-content {
            .rider {
              display: flex;
              background: $color-black;
              color: $color-white;
              font-family: $secondary-font;
              transform: skewX(-24deg);
              box-shadow: 0 2px 5px rgba(0, 0, 0, .18);
              position: relative;

              @include tablet-down {
                transform: none;
              }

              &:hover {
                z-index: 1;
              }

              & + .rider {
                margin-top: rem(4);
              }

              &-name {
                display: flex;
                align-items: center;
                flex-grow: 1;
                padding: rem(10) 0 rem(10) rem(30);
                transform: skewX(24deg);

                @include tablet-down {
                  transform: none;
                  padding: 8px 0 8px 15px;
                }

                &__image {
                  width: rem(60);
                  height: rem(60);
                  border-radius: 50%;
                  overflow: hidden;
                  position: relative;

                  @include tablet-down {
                    width: 48px;
                    height: 48px;
                  }

                  @include phone-down {
                    width: 44px;
                    height: 44px;
                  }
                  
                  img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                &__country {
                  max-width: 24px;
                  margin: rem(36) rem(10) 0 rem(-14);
                  position: relative;

                  @include tablet-down {
                    max-width: 20px;
                  }
                }

                &__full {
                  display: flex;
                  align-items: center;
                  font-family: $secondary-font;
                  font-weight: 700;
                  font-size: rem(52);
                  letter-spacing: rem(-0.5);
                  text-transform: uppercase;
                  margin-left: rem(16);

                  @include tablet-down {
                    font-size: 30px;
                  }

                  @include phone-down {
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 10px;
                    flex-grow: 1;
                  }

                  .name-standings {
                    display: inline-block;
                    width: rem(56);
                    height: rem(56);
                    background: $color-white;
                    color: $color-black;
                    border-radius: rem(7);
                    font-size: rem(33);
                    line-height: rem(56);
                    margin-left: rem(16);
                    text-align: center;

                    @include tablet-down {
                      font-size: 18px;
                      width: 30px;
                      height: 30px;
                      line-height: 30px;
                      font-family: Dinbold;
                      margin-left: 12px;
                    }

                    @include phone-down {
                      font-size: 16px;
                    }
                  }
                }
              }

              &-manufacturer {
                width: rem(152);
                border-left: rem(2) solid $color-white;
                display: flex;
                justify-content: center;
                align-items: center;

                @include tablet-down {
                  width: 80px;
                }

                @include phone-down {
                  width: 48px;
                }

                img {
                  width: auto;
                  max-width: 75%;
                  max-height: 56px;
                  display: inline-block;
                  transform: skewX(24deg);

                  @include tablet-down {
                    transform: none;
                  }
                }
              }

              &-team {
                width: rem(152);
                border-left: rem(2) solid $color-white;
                display: flex;
                justify-content: center;
                align-items: center;

                @include tablet-down {
                  width: 80px;
                }

                @include phone-down {
                  width: 48px;
                }

                img {
                  width: auto;
                  max-width: 75%;
                  max-height: 56px;
                  display: inline-block;
                  transform: skewX(24deg);

                  @include tablet-down {
                    transform: none;
                  }
                }
              }

              &-action {
                width: rem(172);
                border-left: rem(2) solid $color-white;
                display: flex;
                transition: all .4s ease-in-out;
                background: $color-black;
                position: relative;

                @include tablet-down {
                  width: 80px;
                }

                @include phone-down {
                  display: none;
                }

                &:before {
                  position: absolute;
                  content: '';
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  box-shadow: 1px 1px 2px rgba(255, 255, 255, .9);
                  display: none;
                  z-index: 10;
                }

                &:hover {
                  transform: scale(1.1);
                  box-shadow: -2px -3px 0 rgba(255, 255, 255, 1);
                  position: relative;
                  z-index: 10;

                  &:before {
                    display: block;
                  }
                }                

                a {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: rem(24);
                  letter-spacing: rem(1);
                  font-family: $secondary-font;
                  font-weight: 700;
                  text-transform: uppercase;
                  transform: skewX(24deg);
                  position: relative;
                  z-index: 11;

                  @include tablet-down {
                    transform: none;
                    font-size: 18px;
                    text-align: center;
                    padding: 0 5px;
                  }

                  &:after {
                    content: '▸';
                    margin-left: rem(12);

                    @include tablet-down {
                      margin-left: 0;
                    }
                  }
                }
              }

              &:nth-child(even) {
                background: $color-gray-l;

                .rider-action {
                  background: $color-gray-l;
                }
              }
            }
          }
        }
      }
    }
  }
}