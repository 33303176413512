.felds-heading-block {
  .heading-image {
    height: var(--h-desktop);

    @include tablet-down {
      height: var(--h-tablet);
    }

    @include phone-down {
      height: var(--h-phone);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 0;
    }
  }

  .heading-text {
    width: calc(100% - 16rem);
    transform: translateY(-50%);
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    margin-bottom: -72px;

    @include tablet-down {
      width: 100%;
      transform: none;
      margin: 0;
      background: $color-gray;
      text-align: center;
    }

    @include phone-down {
      padding: 20px 10px;
    }

    &:before {
      position: absolute;
      width: 110%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: skewX(-19deg) translateX(-50%);
      content: '';
      background: $color-gray;

      @include tablet-down {
        display: none;
      }
    }

    h2 {
      position: relative;
      color: $color-white;
      text-transform: uppercase;
      text-align: center;
      font-family: $secondary-font;
      font-size: 5rem;
      line-height: 1.8;
      margin: 0;

      @include tablet-down {
        font-size: 3rem;
        padding: 1rem 0;
      }

      @include phone-down {
        line-height: 1.2;
      }
    }
  }
}