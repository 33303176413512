.felds-signup-block {
  padding: rem(100) 15px;

  .felds-block-wrapper {
    max-width: 930px;
    margin: 0 auto;
  }

  .block-title {
    font-family: $secondary-font;
    font-size: 3vw;
    font-weight: 900;
    color: $color-black;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2;
    color: $color-blue;
  }

  .block-desc {
    font-family: $primary-font;
    font-size: 1vw;
    color: $color-black;
    text-align: center;
    padding: rem(40) 0 rem(20);
  }

  .block-content {
    color: $color-gray;
    font-size: rem(18);

    .fsBody .fsLabel, 
    .fsBody label {
      color: $color-gray;
      font-size: rem(18);
    }

    .fsBody .fsForm {
      float: none;
    }
    
    .fsRow {
      label {
        font-size: rem(18);
        color: $color-gray;
      }

      input[type=text],
      input[type=email],
      textarea,
      select {
        border: 1px solid $color-gray;
      }

      input[type=checkbox] {
        &:before {
          border: 1px solid $color-gray;
          width: 14px;
          height: 14px;
        }
      }

      .fsOptionLabel {
        font-size: rem(14);
        display: flex;
        align-items: center;

        input[type=checkbox] {
          width: auto;
          height: auto;
        }
      }

      .fsRowBody {
        font-size: rem(18);
      }
    }

    input[type=submit] {
      background: $color-blue !important;
      font-size: rem(25) !important;
    }
  }
}
