.single-post-container {
  .post-header {
    &__image {
      height: 350px;
  
      @include tablet-down {
        height: 250px;
      }
  
      @include phone-down {
        height: 134px;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 0;
      }
    }
  
    &__text {
      width: calc(100% - 16rem);
      transform: translateY(-50%);
      max-width: 1140px;
      margin: 0 auto;
      position: relative;
      text-align: center;
      padding: rem(30) 0;

      @include tablet-down {
        transform: none;
        width: 100%;
        padding: 25px 20px;
      }

      @include phone-down {
        
      }
  
      &:before {
        position: absolute;
        width: 110%;
        height: 100%;
        top: 0;
        left: 50%;
        transform: skewX(-19deg) translateX(-50%);
        content: '';
        background: $color-gray;

        @include tablet-down {
          transform: translateX(-50%);
        }
      }
  
      h2 {
        position: relative;
        color: $color-white;
        text-transform: uppercase;
        font-family: $secondary-font;
        font-size: rem(40);
        line-height: 1.25;
        margin: 0;
      }

      .byline {
        font-size: rem(16);
        line-height: 1.25;
        color: $color-white;
        position: relative;
      }
    }
  }

  .post-content {
    width: 66%;
    margin: 0 auto rem(80);

    @include tablet-down {
      width: 100%;
      margin: 0;
      padding: 50px 30px;
    }

    @include phone-down {
      padding: 30px 20px;
    }

    p {
      margin: 0 0 rem(26) 0;
      font-size: rem(16);
      line-height: 1.6;
    }
  }
}