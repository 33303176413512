.page-search {
  .page-header {
    height: 200px;

    @include phone-down {
      height: 134px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .search-form {
    display: flex;
    position: relative;
    padding: rem(40) rem(20);
    margin: rem(50) 0;
    justify-content: center;
    align-items: center;

    @include phone-down {
      display: block;
      text-align: center;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%) skewX(-19deg);
      background: $color-gray;

      @include tablet-down {
        width: 100vw;
        transform: translateX(-50%);
      }
    }

    > * {
      position: relative;
    }

    &__label {
      font-size: rem(24);
      font-family: $tertiary-font;
      color: $color-white;
      text-transform: uppercase;

      @include phone-down {
        padding-bottom: 15px;
      }
    }

    form {
      display: flex;
      margin-left: rem(10);

      @include phone-down{
        margin: 0;
      }
      
      input {
        background: $color-white;
        border: none;
        margin: 0;
        padding: rem(5) rem(10);
        width: 100%;
        max-width: 320px;
        border-radius: 0;
        outline: none;
        box-sizing: border-box;
      }

      button {
        font-family: $secondary-font;
        font-size: rem(24);
        color: $color-white;
        background: $color-blue;
        text-transform: uppercase;
        border: none;
        padding: rem(10) rem(30);
      }
    }
  }

  .search-results {
    padding-bottom: rem(50);

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .no-result {
        flex: 0 0 100%;
        text-align: center;
        font-family: $secondary-font;
        font-size: rem(32);
        font-weight: 700;

        @include phone-down {
          font-size: 22px;
        }
      }

      .item {
        flex: 0 0 31.8%;
        margin-bottom: rem(40);
        position: relative;
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.05), 0 3px 10px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        transition: all .3s ease-in-out;

        &:hover {
          transform: scale(1.02);
        }

        @include tablet-down {
          flex: 0 0 48% !important;
        }

        @include phone-down {
          flex: 0 0 100% !important;
        }
        
        &-image {
          a {
            display: block;

            img {
              width: 100%;
            }
          }
        }

        &-content {
          flex-grow: 1;
          padding: rem(20);
          background: $color-white;
          display: flex;
          flex-direction: column;

          &__cat {
            span {
              font-size: 1rem;
              line-height: 1.8;
              font-weight: 700;
              color: $color-blue;
              text-transform: uppercase;
              font-family: $secondary-font;

              & + span {
                margin-left: rem(10);
              }
            }
          }

          &__title {
            padding: rem(5) 0 rem(15);
            flex-grow: 1;

            a {
              font-family: $secondary-font;
              font-size: rem(28);
              font-weight: 700;
              line-height: 1;
              color: $color-black;

              &:hover {
                color: $color-blue;
              }
            }
          }

          &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-date {
              color: $color-gray;
              font-size: rem(14);
              line-height: 1.3;
            }

            &-more {
              a {
                color: $color-blue;
                font-size: rem(14);
                line-height: 1.3;
                font-weight: 600;

                &:hover {
                  text-decoration: underline;
                }

                i {
                  font-size: rem(12);
                }
              }
            }
          }
        }

        &.item-rider {
          .item-image {
            position: relative;
            height: 0;
            padding-bottom: 56%;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    &__pagenavi {
      text-align: center;

      .wp-pagenavi {
        display: inline-flex;
        background: $color-white;
        font-size: rem(16);

        span {
          border: none;
          padding: .8rem 1.4rem;
          background: $color-skin;
          font-weight: 400;

          @include phone-down {
            padding: 5px 10px;
          }

          &.current {
            background: $color-blue;
          }
        }

        a {
          padding: .8rem 1.4rem;
          border: none;
          background: $color-skin;

          @include phone-down {
            padding: 5px 10px;
          }
        }
      }
    }
  }
}