/**
 * Define font families and their font settings.
 * $font-family, $file-path, $file-name, $font-weight, $font-style
 */
$fonts: (
  ("Dincond", $font-path, "DINCond-Medium", 400, null),
  ("Dincond", $font-path, "DINCond-Bold", 700, null),
  ("Dinbold", $font-path, "Dinbold", 700, null),
  ("Gotham", $font-path, "Gotham-Book", 400, null),
  ("Gotham", $font-path, "Gotham-Bold", 700, null),
  ("Gotham", $font-path, "Gotham-Black", 900, null),
  ("PopWarner", $font-path, "PopWarner", 400, null),
);

// Loop over $fonts and output the font-face rules for each using their settings.
@each $font-family, $file-path, $file-name, $font-weight, $font-style in $fonts {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}#{$file-name}.woff2") format("woff2"),
    url("#{$file-path}#{$file-name}.woff") format("woff");

    @if ($font-weight) {
      font-weight: $font-weight;
    }

    @if ($font-style) {
      font-style: $font-style;
    }
  }
}