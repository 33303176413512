.ticket-container {
  background: $color-white;
  overflow: hidden;

  .ticket-header {
    &__image {
      height: 400px;
  
      @include tablet-down {
        height: 300px;
      }
  
      @include phone-down {
        height: 200px;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 0;
      }
    }
  
    &__summary {
      width: calc(100% - 16rem);
      transform: translateY(-100px);
      max-width: 1140px;
      margin: 0 auto -100px;
      position: relative;
      text-align: center;
      padding: rem(30) rem(30) 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      color: $color-white;

      @include tablet-down {
        width: 100%;
        background: $color-gray;
        display: block;
      }

      @include phone-down {
        padding: 20px 15px 0;
      }
  
      &:before {
        position: absolute;
        width: 110%;
        height: 100%;
        top: 0;
        left: 50%;
        transform: skewX(-17deg) translateX(-50%);
        content: '';
        background: $color-gray;

        @include tablet-down {
          display: none;
        }
      }
  
      > * {
        position: relative;
      }

      .summary-title {
        flex: 0 0 100%;
        font-size: rem(25.6);
        font-weight: 700;

        @include phone-down {
          font-size: 20px;
        }
      }

      .summary-times {
        &__pday {
          font-size: rem(64);
          font-family: $tertiary-font;
          color: $color-blue;
          text-transform: uppercase;
          line-height: 1;

          @include phone-down {
            font-size: 40px;
          }

          span {
            color: $color-white;
          }
        }

        &__ptime {
          font-size: rem(35.2);
          font-weight: 700;

          @include phone-down {
            font-size: 28px;
          }
        }

        &__opentime {
          font-size: rem(20.8);

          @include phone-down {
            font-size: 16px;
          }
        }
      }

      .summary-venue {
        font-size: rem(20.8);

        @include tablet-down {
          padding: 30px 0;
        }

        @include phone-down {
          font-size: 16px;
          padding: 20px 0;
        }

        &__name {
          font-size: rem(28.8);
          font-weight: 700;

          @include phone-down {
            font-size: 20px;
          }
        }
      }

      .summary-link {
        a {
          display: block;
          text-align: center;
          padding: rem(10) rem(32);
          font-size: rem(19.2);
          font-weight: 700;
          font-family: $secondary-font;
          background: $color-white;
          color: $color-gray;
          text-transform: uppercase;
          transition: all 250ms ease-in-out;

          &:hover {
            color: $color-white;
            background: $color-blue;
          }
        }
      }

      .ticket-menu {
        flex: 0 0 100%;
        margin-top: rem(30);
        margin-bottom: 1px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 100vw;
          height: 1px;
          background: rgba(255, 255, 255, 0.15);
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }

        ul {
          list-style: none;
          display: flex;
          justify-content: center;
          padding: 0;
          margin: 0;
          
          li {
            position: relative;

            &:before {
              display: block;
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border-left: 1px solid rgba(255, 255, 255, 0.15);
              transform: skewX(-15deg);
              transition: all 250ms ease-in-out;
            }

            &:last-child {
              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                right: 0;
                top: 0;
                border-left: 1px solid rgba(255, 255, 255, 0.15);
                transform: skewX(-15deg);
              }
            }

            &:hover {
              &:before {
                background: $color-blue;
              }
            }

            a {
              display: block;
              padding: rem(12) rem(24);
              font-family: $secondary-font;
              font-size: rem(20);
              text-transform: uppercase;
              line-height: 1.5;
              position: relative;
              z-index: 1;

              @include phone-down {
                padding: 10px 10px;
                font-size: 15px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .ticket-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include tablet-down {
      display: block;
    }

    .detail-block {
      padding-bottom: rem(80);
      flex: 0 0 100%;

      &__title {
        font-size: rem(40);
        font-family: $secondary-font;
        text-transform: uppercase;
        color: $color-gray;
        letter-spacing: rem(-1);
        line-height: 1.25;
        margin-bottom: rem(10);

        span {
          color: $color-blue;
        }
      }

      &__content {
        line-height: 1.5;

        h3 {
          font-size: rem(19.2);
          font-family: $primary-font;
        }
      }
    }

    .ticket-info {
      flex: 0 0 45%;
    }

    .ticket-venue {
      flex: 0 0 45%;

      .venue-name {
        font-size: rem(19.2);
        font-weight: 600;
        text-transform: uppercase;
      }

      .venue-address {
        a {
          color: $color-blue;
          text-decoration: underline;
        }
      }

      .venue-urls {
        list-style: none;
        padding-left: rem(25);

        li {
          line-height: 1.5;

          &:before {
            content: "";
            position: absolute;
            background-color: $color-blue;
            width: 8px;
            height: 8px;
            margin-left: -20px;
            margin-top: 8px;
          }

          a {
            color: $color-blue;

            span {
              text-decoration: underline;
              font-weight: 700;
            }

            &:hover {
              span {
                text-decoration: none;
              }
            }
          }
        }
      }

      .venue-image {
        text-align: center;

        img {
          width: 100%;
        }

        a {
          margin-top: rem(20);
          display: inline-block;
          text-align: center;
          padding: rem(8) rem(25);
          font-size: rem(17.6);
          font-weight: 700;
          background: $color-white;
          border: 3px solid $color-blue;
          color: $color-gray;
          text-transform: uppercase;
          transition: all 250ms ease-in-out;

          &:hover {
            color: $color-white;
            background: $color-blue;
          }
        }
      }
    }

    .ticket-schedule {
      padding-bottom: rem(40);

      a {
        color: $color-blue;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      .schedules {
        display: flex;
        padding: 0 0 30px 0;

        @include phone-down {
          display: block;
        }

        .item {
          flex-grow: 1;

          & + .item {
            margin-left: 3%;

            @include phone-down {
              margin-left: 0;
            }
          }

          &-time {
            font-size: rem(20);
            font-weight: 700;
            color: $color-blue;
            border-bottom: 2px solid $color-blue;
            padding: rem(15) 0 rem(8) 0;
            margin: 0 0 rem(10) 0;
            text-transform: uppercase;
          }

          &-title {
            
          }
        }
      }

      .event-sessions {
        display: none;
        
        &__title {
          font-size: rem(20);
          font-weight: 700;
          color: $color-blue;
          border-bottom: 2px solid $color-blue;
          padding: rem(15) 0 rem(5) 0;
          text-transform: uppercase;
        }

        &__content {
          .item {
            display: flex;
            padding: rem(10) 0;
            border-bottom: 1px solid $color-gray-l;

            @include tablet-down {
              flex-wrap: wrap;
            }

            @include phone-down {
              display: block;
            }
  
            &-date {
              flex: 0 0 30%;

              @include tablet-down {
                flex: 0 0 40%;
              }
            }
  
            &-time {
              flex: 0 0 20%;

              @include tablet-down {
                flex: 0 0 40%;
              }
            }
  
            &-class {
              flex: 0 0 10%;

              @include tablet-down {
                flex: 0 0 20%;
              }
            }
  
            &-name {
              flex: 0 0 40%;
              font-weight: 700;

              @include tablet-down {
                flex: 0 0 100%;
                padding-top: 5px;
              }
            }
          }
        }        
      }
    }

    .ticket-warning {
      text-align: center;
    }

    .ticket-broadcast {
      position: relative;
      color: $color-white;
      padding: 0;
      margin-bottom: 50px;
      
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%) skewX(-19deg);
        background: $color-gray;
        width: 110%;
        height: 100%;

        @include tablet-down {
          transform: translateX(-50%);
          width: 100vw;
        }
      }

      > div {
        position: relative;
      }

      .detail-block__title {
        color: $color-white;
        padding: 20px 0;
        margin: 0;
      }

      .broadcast-schedule {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          position: relative;

          @include tablet-down {
            display: block;
          }

          &:before {
            content: '';
            height: 1px;
            width: 100vw;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background: $color-white;
          }

          &-time {
            flex: 0 0 50%;
            display: flex;
            white-space: nowrap;
            align-items: center;
          }

          &-live {
            background: $color-pink;
            color: $color-white;
            text-transform: uppercase;
            padding: 3px 10px;
            transform: skewX(-19deg);
            margin-left: 20px;

            span {
              display: block;
              transform: skewX(19deg);
              font-weight: 700;
              font-family: $secondary-font;
            }
          }

          &-title {
            flex: 0 0 25%;

            @include tablet-down {
              padding: 10px 0;
            }
          }

          &-tvstream {
            flex: 0 0 25%;
            text-align: right;

            @include tablet-down {
              text-align: left;
            }

            a {
              & + a {
                margin-left: 15px;
              }
            }

            img {
              display: inline-block;
              max-height: 25px;
              width: auto;
              transition: all 250ms linear;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }

    .ticket-trackinfo {
      .trackinfo {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @include tablet-down {
          display: block;
        }

        &-image {
          flex: 0 0 47%;
        }

        &-desc {
          flex: 0 0 47%;

          @include tablet-down {
            padding-top: 20px;
          }
        }
      }
    }

    .ticket-fanfest {
      .fanfest-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include phone-down {
          display: block;
        }

        &__images {
          flex: 0 0 47%;

          .fanfest-carousel-main {
            overflow: hidden;

            .flickity-fullscreen-button {
              display: none;
            }

            &.is-fullscreen {
              padding: 0;

              .flickity-fullscreen-button {
                display: block;
              }
              
              .flickity-cell {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
  
                img {
                  padding: 20px;
                  width: auto;
                  max-height: 100%;
                }
              }
            }

            .flickity-button:disabled {
              display: none;
            }

            .flickity-prev-next-button {
              background: none;
              width: 20px;
              height: 40px;
              box-shadow: none !important;

              &:before {
                content: '';
                width: 50px;
                height: 40px;
                background: $color-black;
                transform: skewX(-20deg) translateX(-15px);
                display: block;
              }

              &.previous {
                &:before {
                  transform: skewX(-20deg) translateX(-30px);
                }
              }

              svg {
                filter: brightness(50) drop-shadow(4px 4px 12px #000);
                width: 60%;

                path {
                  fill: $color-blue;
                }
              }  
            }
          }

          .fanfest-carousel-nav {
            margin-top: rem(8);

            .item {
              width: 16%;

              & + .item {
                margin-left: 0.8%;
              }

              &.is-selected {
                &:before {
                  content: '';
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  border: 3px solid $color-blue;
                  position: absolute;
                  box-sizing: border-box;
                }
              }
            }            
          }
        }

        &__desc {
          flex: 0 0 47%;

          @include phone-down {
            padding-top: 20px;
          }

          &-hours {
            margin-bottom: rem(10);
            color: $color-blue;
            font-weight: 700;
          }

          &-desc {
            
          }

          &-link {
            padding-top: rem(5);

            a {
              padding: rem(5) rem(20);
            }
          }
        }
      }
    }

    .ticket-vip {
      #vip-experiences {
        padding-top: rem(20);
      }
    }

    .ticket-news {
      .felds-latest-posts-block {
        padding: 0;
      }
    }

    .ticket-sponsors {
      .detail-block__title {
        text-align: center;
      }
      
      .sponsor-list {
        display: flex;
        justify-content: center;
        align-items: center;

        .item {
          margin: 0 rem(24);

          img {
            width: auto;
            height: auto;
            max-height: rem(100);
          }
        }
      }
    }
  }
}