.single-rider-container {
  .rider-header {
    background: $color-gray;
    color: $color-white;
    overflow: hidden;
    padding: 0 0 rem(30) 0;

    .header-sides {
      display: flex;
      justify-content: space-between;

      @include phone-down {
        display: block;
      }

      .header-jumbotron {
        display: none;
        height: 160px;
        position: relative;

        @include phone-down {
          display: block;
        }

        .jumbotron-image {
          width: 100vw;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:after {
            content: '';
            width: 100%;
            height: 8px;
            background: var(--team-color);
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }

      .side-profile {
        width: 240px;

        @include phone-down {
          text-align: center;
          width: 100%;
          margin-top: -84px;
        }

        &__manufacturer {
          padding-bottom: 20px;

          @include phone-down {
            display: none;
          }

          img {
            max-width: 200px;
          }
        }

        &__manufacturer-mobile {
          padding: 20px 0;
          display: none;

          img {
            max-width: 200px;
            max-height: 56px;
            width: auto;
            display: inline-block;
          }

          @include phone-down {
            display: block;
          }
        }

        &__photo {
          position: relative;
          display: inline-block;

          .photo {
            width: 168px;
            height: 168px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .number {
            position: absolute;
            right: 0;
            bottom: 0;
            width: rem(60);
            height: rem(60);
            background: $color-white;
            color: $color-black;
            border-radius: rem(15);
            font-size: rem(40);
            line-height: rem(56);
            text-align: center;
            font-family: 'Dinbold';
            font-style: italic;
          }
        }

        &__name {
          padding: 6px 0 10px;
          font-family: $secondary-font;
          text-transform: uppercase;
          font-size: rem(44);
          line-height: 1;
          color: $color-white;
        }

        &__location {
          display: flex;
          align-items: center;
          font-size: rem(12);

          @include phone-down {
            justify-content: center;
          }

          img {
            width: 22px;
            margin-right: rem(10);
          }
        }

        &__socials {
          margin-top: rem(12);

          @include phone-down {
            margin: 0;
          }

          a {
            font-size: rem(16);
            display: inline-block;
            transition: all 250ms ease-in-out;

            @include phone-down {
              font-size: 24px;
            }

            & + a {
              margin-left: rem(8);

              @include phone-down {
                margin-left: 15px;
              }
            }

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        &__shop {
          margin-top: rem(20);

          a {
            display: inline-block;
            font-size: rem(14);
            font-weight: 700;
            text-transform: uppercase;
            padding: rem(6) rem(10);
            border: 1px solid $color-white;
            transition: all 250ms ease-in-out;

            &:hover {
              background: rgba(255, 255, 255, .1);
            }
          }
        }
      }

      .side-data {
        flex-grow: 1;
        padding-left: 72px;

        @include phone-down {
          padding: 0;
        }

        &__jumbotron {
          position: relative;
          height: 200px;

          @include phone-down {
            display: none;
          }

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 200px 180px 0 0;
            border-color: $color-gray transparent transparent transparent;
            transform: rotate(360deg);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
          }
          
          .jumbotron-image {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(50vw + 400px);
            height: 100%;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &:after {
              content: '';
              width: 100%;
              height: rem(16);
              background: var(--team-color);
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }          
        }

        &__content {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid var(--team-color);
          margin: rem(48) 0 0 rem(30);

          @include phone-down {
            display: block;
            margin-left: 0;
          }

          .item {
            flex: 0 0 50%;
            padding: rem(8) rem(12) rem(8) 0;
            font-size: rem(18);
            line-height: 1.2;

            @include phone-down {
              padding-right: 0;
            }

            &:before {
              position: absolute;
              left: 0;
              content: '';
              height: 1px;
              width: 100%;
              background: var(--team-color);
              margin-top: rem(-8);
            }
          }
        }
      }
    }
  }

  .rider-bio {
    padding: rem(50) 0;

    .bio-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include phone-down {
        display: block;
      }

      .item {
        flex: 0 0 48%;

        @include phone-down {
          & + .item {
            margin-top: rem(35);
          }
        }
        
        &.item-description {
          flex: 0 0 100%;
          margin-top: rem(35);
        }

        &-title {
          font-family: $secondary-font;
          font-size: rem(35);
          font-weight: bold;
          padding-bottom: rem(35);

          i {
            margin-right: rem(8);
          }
        }

        &-content {
          line-height: 1.6;

          ul {
            list-style: none;
            margin: 0;
            padding: 0 0 0 rem(30);

            li {
              position: relative;

              &:before {
                content: "";
                position: absolute;
                background-color: $color-blue;
                width: rem(8);
                height: rem(8);
                margin-left: rem(-20);
                margin-top: rem(8);
              }
            }
          }

          strong {
            color: $color-blue;
          }
        }
      }
    }
  }

  .rider-socialbox {
    position: relative;
    background: $color-black;
    color: $color-white;
    padding: rem(100) 0;
    overflow-x: hidden;

    &:before {
      width: 8000px;
      left: -2000px;
      content: ' ';
      position: absolute;
      background: $color-skin;
      height: 100px;
      z-index: 0;
      top: -20px;
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }

    .socialbox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet-down {
        display: block;
      }

      &-frame {
        flex: 0 0 33%;

        &__title {
          margin-bottom: 10px;

          a {
            color: $color-white;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        &__content {
          position: relative;
          height: 0;
          padding-bottom: 120%;

          iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }

        &__socials {
          display: flex;
          justify-content: flex-end;

          a {
            display: block;
            width: rem(36);
            height: rem(36);
            background: rgba(255, 255, 255, .15);
            transition: all 250ms ease-in-out;
            color: $color-white;
            font-size: rem(18);
            line-height: rem(36);
            text-align: center;

            &:hover {
              background: rgba(255, 255, 255, .2);
            }
          }
        }
      }

      &-gallery {
        flex: 0 0 63%;

        @include tablet-down {
          padding-top: rem(30);
        }

        &__title {
          text-align: center;
          font-size: rem(36);
          font-family: $secondary-font;
          font-weight: 700;
          line-height: 1.5;
          text-transform: uppercase;
        }

        &__content {
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            transition: all .5s ease-in-out;
            opacity: 0;
            
            a {
              display: block;

              img {
                box-sizing: border-box;
                min-width: 480px;
                min-height: 240px;
                filter: blur(2px);
                border: 60px solid transparent;
              }
            }
            
            &.slide-prev {
              margin-left: 200px;
              opacity: 1;

              img {
                transition: all .5s ease-in-out;
              }
            }

            &.slide-next {
              margin-left: -200px;
              opacity: 1;

              img {
                transition: all .5s ease-in-out;
              }
            }

            &.is-selected {
              z-index: 10;
              height: 100%;
              opacity: 1;

              img {
                border: 0;
                transition: all .5s ease-in-out;
                
                filter: blur(0);
              }
            }
          }
        }
      }
    }
  }

  .rider-youtubes {
    position: relative;
    padding: 0 0 rem(100) 0;

    &:before {
      content: '';
      position: absolute;
      height: rem(240);
      width: 100%;
      background: $color-black;
      left: 0;
      top: 0;

      @include phone-down {
        height: 30vw;
      }
    }

    iframe {
      border: rem(10) solid var(--team-color);
    }

    .youtube-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include phone-down {
        display: block;   
      }

      .item {
        flex: 0 0 49%;
        margin-top: 2%;

        &:nth-child(1) {
          margin-top: 0;
          flex: 0 0 100%;
        }        
      }
    }
  }

  .block-accordion {
    overflow-x: hidden;
    margin-bottom: 1px;

    > .container {
      @include phone-down {
        padding-left: 0;
        padding-right: 0;
      }
    }
    
    &__header {
      position: relative;
      display: flex;
      justify-content: space-between;
      color: $color-white;

      @include phone-down {
        padding-left: 15px;
        padding-right: 15px;
      }

      &:before {
        content: '';
        height: 100%;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: #244a95;
      }

      > * {
        position: relative;
      }

      .header-title {
        font-family: $secondary-font;
        font-size: rem(40);
        font-weight: 700;
        line-height: 1.25;
        padding: rem(10) 0;
        text-transform: uppercase;

        @include phone-down {
          font-size: 24px;
        }
      }

      .header-control {
        font-size: rem(26);
        padding: 0 rem(22);
        border-left: 1px solid $color-white;
        border-right: 1px solid $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include phone-down {
          font-size: 14px;
        }
      }
    }

    &__content {
      padding: 0 0 rem(30) 0;

      @include phone-down {
        overflow-x: auto;
      }

      table {
        width: 100%;

        thead {
          th {
            font-weight: 700;
            text-transform: uppercase;
            padding: rem(14) 0;
            text-align: left;
            border-bottom: 1px solid #9c9c9c;

            @include phone-down {
              padding: rem(14) rem(15);
            }

            &:last-child {
              text-align: right;
            }
          }
        }

        tbody {
          tr {
            &:first-child {
              td {
                padding-top: rem(20);
              }
            }

            td {
              padding: rem(8) 0;
              text-align: left;
              font-size: rem(16);
              line-height: 1.25;

              @include phone-down {
                padding: rem(8) rem(15);
              }

              &:last-child {
                text-align: right;
              }
            }
          }          
        }
      }
    }
  }
}