.felds-galleries-block {
  padding: rem(50) 0;

  .block-filter {
    padding: 0 0 rem(50) 0;

    .filter-field {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;      

      select {
        width: 100%;  
      }
    }
  }

  .block-header {
    padding: 0 1% rem(20);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: rem(32);
      font-family: $secondary-font;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__action {
      a {
        color: $color-blue;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .block-content {
    display: flex;
    flex-wrap: wrap;

    @include phone-down {
      display: block;
    }
    
    .item {
      flex: 0 0 23%;
      margin: 0 1% 2%;
      background: $color-white;

      @include tablet-down {
        flex: 0 0 48%;
      }
      
      &-image {
        position: relative;
        height: 200px;

        @include tablet-down {
          height: rem(160);
        }
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-title {
        font-family: $secondary-font;
        font-size: rem(28.8);
        font-weight: 700;
        text-align: center;
        line-height: 1;
        padding: rem(20);
      }
    }
  }

  .block-action {
    text-align: center;
    padding: rem(30) 0 0 0;
    
    .wp-pagenavi {
      display: inline-flex;
      background: $color-white;
      font-size: rem(16);

      span {
        border: none;
        padding: .8rem 1.4rem;
        background: $color-skin;
        font-weight: 400;

        &.current {
          background: $color-blue;
        }
      }

      a {
        padding: .8rem 1.4rem;
        border: none;
        background: $color-skin;
      }
    }
  }
}
