.felds-gallery-block {
  padding: rem(50) 0;

  .block-header {
    margin: 0 1%;
    padding: 0 0 rem(20) 0;

    a {
      color: $color-blue;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .block-content {
    .gallery-bundle {
      .item {
        background: $color-white;

        a {
          text-decoration: none;
          color: $color-black;
        }
        
        &-image {
          position: relative;
          
          img {
            width: 100%;
            height: auto;
          }
        }

        &-title {
          font-family: $secondary-font;
          font-size: rem(28.8);
          font-weight: 700;
          line-height: 1;
          padding: rem(20);
        }
      }
    }

    .gallery-grid {
      display: flex;
      flex-wrap: wrap;

      @include phone-down {
        display: block;
      }
      
      .item {
        flex: 0 0 23%;
        margin: 0 1% 2%;
        background: $color-white;

        @include tablet-down {
          flex: 0 0 48%;
        }
        
        &-image {
          position: relative;
          height: 200px;

          @include tablet-down {
            height: rem(160);
          }
          
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    
  }
}
