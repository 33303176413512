.event-results {
  padding: rem(50) 0;

  .results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include phone-down {
      flex-wrap: wrap;
      text-align: center;
    }

    &__summary {
      @include phone-down {
        order: 2;
        flex: 0 0 100%;
        margin-top: 30px;
      }

      h2 {
        font-size: rem(40);
        text-transform: uppercase;
        margin: 0 0 rem(16) 0;
      }

      div {
        font-size: rem(24.7);
        font-family: $secondary-font;
        line-height: 1.3;
      }
    }

    &__logo {
      width: 244px;

      @include phone-down {
        order: 1;
        flex: 0 0 100%;
        
        img {
          display: inline-block;
          max-width: 80%;
        }
      }
    }
  }

  .race-results {
    .pdfs-list {
      display: flex;
      padding: rem(25) 0;

      @include tablet-down {
        width: 85%;
        margin: 0 auto;
      }

      .item {
        flex: 0 0 50%;
        margin: 0 1px;
        background: $color-gray;
        transform: skewX(-19deg);
        padding: rem(20);
        text-align: center;

        a {
          display: inline-block;
          font-family: $secondary-font;
          font-size: rem(18);
          transform: skewX(19deg);
          color: $color-white;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
    }

    .entry-list {
      padding: rem(25) 0;
      display: flex;
      justify-content: space-between;

      @include tablet-down {
        display: block;
      }

      .item {
        flex: 1 0 23.5%;
        text-align: center;

        & + .item {
          margin-left: rem(20);

          @include tablet-down {
            margin: 15px 0 0 0;
          }
        }

        &-title {
          font-family: $secondary-font;
          font-size: rem(48);
          font-weight: 700;
          padding: 0 0 rem(8) 0;

          @include tablet-down {
            font-size: 35px;
          }

          @include phone-down {
            font-size: 30px;
          }
        }

        &-link {
          a {
            display: block;
            background: $color-green;
            color: $color-black;
            font-family: $secondary-font;
            font-size: rem(20);
            padding: rem(10) 0;
            line-height: 1.3;
            transition: all 250ms ease-in-out;

            &:hover {
              transform: scale(1.025);
            }
          }
        }
      }
    }

    .event-date {
      background: $color-blue;
      color: $color-white;
      text-align: center;
      font-family: $secondary-font;
      font-size: rem(24);
      padding: rem(10);
      text-transform: uppercase;
    }

    .sessions {
      padding: rem(25) 0;
      display: flex;
      justify-content: space-between;

      @include tablet-down {
        display: block;
      }

      .class {
        flex: 1 0 23.5%;
        padding: 0 rem(10);
        text-align: center;

        &-name {
          font-family: $secondary-font;
          font-size: rem(48);
          font-weight: 700;
          padding: 0 0 rem(8) 0;

          @include tablet-down {
            font-size: 35px;
          }

          @include phone-down {
            font-size: 30px;
          }
        }

        &-sessions {
          .item {
            & + .item {
              margin-top: rem(40);
            }

            &-title {
              font-size: rem(24);
              font-family: $secondary-font;
              font-weight: 700;
            }

            &-time {
              padding: rem(10) 0;
              color: gray;
              text-transform: uppercase;
              font-size: rem(20);
              font-family: $secondary-font;
            }

            &-links {
              a {
                display: block;
                background: $color-blue;
                color: $color-white;
                padding: rem(10) 0;
                font-size: rem(20);
                font-family: $secondary-font;
                line-height: 1.3;
                transition: all 250ms ease-in-out;

                & + a {
                  margin-top: 3px;
                }

                &.green {
                  background: $color-green;
                  color: $color-black;
                }

                &:hover {
                  transform: scale(1.025);
                }                
              }
            }
          }
        }
      }
    }
  }
}

.event-data-container {
  padding: rem(50) rem(32);

  .event-header {
    font-family: $secondary-font;
    font-size: rem(24);
    line-height: 1.25;
    font-weight: 700;

    div {
      & + div {
        margin-top: rem(20);
      }
    }
  }

  .data-title {
    font-family: $secondary-font;
    font-size: rem(32);
    font-weight: 700;
    line-height: 1.25;
    padding: rem(32);
    background: $color-gray;
    color: $color-white;
    text-align: center;
    margin: rem(30) 0;
  }

  .data-content {
    h3 {
      font-size: rem(26.6);
      font-family: $primary-font;
      font-weight: 400;
      margin-top: rem(30);
    }
  }

  .individual-segments-times {
    .rider {
      & + .rider {
        margin-top: rem(30);
      }

      &-name {
        font-family: $secondary-font;
        font-size: rem(21.33);
        font-weight: 700;
        line-height: 1.25;
      }

      &-bike {
        font-family: $secondary-font;
        font-size: 13.33px;
        font-weight: 700;
        line-height: 1.25;
        padding: rem(3) 0 rem(10);
      }
    }
  }

  .fastest-segment-times {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.75%;

    .segment {
      flex: 0 0 33.33%;
      padding: 0 1.75% rem(30);

      &-title {
        font-family: $secondary-font;
        font-size: rem(21.33);
        font-weight: 700;
        line-height: 1.25;
        padding: 0 0 rem(10);
        text-transform: uppercase;
      }
    }
  }

  .individual-lap-times {
    display: flex;
    flex-wrap: wrap;
    padding: rem(20) rem(50);

    .rider {
      flex: 0 0 28.33%;
      margin: 0 2.5% rem(50);

      .data-table {
        thead {
          th {
            white-space: nowrap;

            &:first-child {
              width: 45%;
            }
          }
        }

        tbody {
          td {
            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .lap-chart {
    .indicator {
      &-color {
        width: 50px;
        height: 40px;
        border: 2px solid $color-black;
        background: #ccc;
      }

      &-desc {
        font-size: 14.6px;
        line-height: 1.5;
      }
    }

    table {
      max-width: 1300px;
      margin: 0 auto;

      thead {
        th {
          padding: rem(15);
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          width: 40px;

          &:nth-child(2) {
            width: 150px;
          }

          &.data-cell {
            border: 1px solid $color-gray;
            background: $color-gray;
            color: $color-white;
            text-align: center;
            width: auto;
          }
        }
      }

      tbody {
        tr {    
          td {
            padding: rem(15);
            color: $color-black;
            text-align: left;
            font-size: 14px;
            line-height: 1.4;

            &.data-cell {
              border: 1px solid $color-gray;
              background: $color-white;
              text-align: center;
            }

            &.data-cell-indicate {
              background: #ccc;
            }
          }
        }
      }
    }
  }

  .provisional-results {
    .overall-data {
      font-size: 1.5em;
      font-family: $secondary-font;
      font-weight: 700;
      padding: rem(20) 0;

      div {
        padding: rem(10) 0;
      }
    }
  }
}