#custom-grid{
  .container{
    .heading{
      // color: $gray;
      font-size: 30px;
      // font-family: $font-din;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1;
      // border-bottom: solid 1px $gray;
      padding: 20px 0;
      margin-bottom: 20px;
    }
    .wrapper{
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(
        auto-fit,
        minmax(
          280px,
          1fr
        )
      );
      .item{
        display: flex;
        flex-direction: column;
        gap: 15px;
        text-align: center;
        .title{
          font-size: 24px;
          // color: $black;
          // font-family: $font-din;
        }
        .content{
          font-size: 16px;
        }
        .btn {
          letter-spacing: 0;
          font-size: 16;
          line-height: 2.1;
          font-weight: 400;
          position: relative;
          background: none;
          border: none;
          padding: 0 rem(50);
    
          &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform: skew(-19deg);
            background: $color-blue;
            content: '';
          }
    
          span {
            position: relative;
            color: $color-white;
          }
        }
      }
      @include tablet-up{
        &.cols-one{
          grid-template-columns: 1fr;
        }
        &.cols-two{
          grid-template-columns: 1fr 1fr;
        }
        &.cols-three{
          grid-template-columns: 1fr 1fr 1fr;
        }
        &.cols-four{
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }
  }
}