h1, h2, h3, h4, h5, h6 {
  font-family: $secondary-font;
  margin: 0.5em 0;
}

.wp-block-columns {
  &.alignwide {
    justify-content: space-between;
    max-width: 1170px;
    padding: 40px $gutter-size;
    margin: 0px auto !important;
    width: 100%;
  }

  &.columns-section {
    justify-content: space-between;
    margin: 0px auto !important;
    max-width: 1170px;
    padding: 40px $gutter-size;
    width: 100%;
    gap: 5% !important;

    h1 {
      padding: 0 0 0.5rem;
      border-bottom: 2px solid $color-gray;
      margin: 2rem 0 2rem;
    }
  }

  &.standings-columns {
    max-width: 1400px;
  }

  p {
    padding: .7em 0;
    margin: 0;
    line-height: 1.5 !important;
    color: $color-black;
  }
  
  a {
    color: $color-blue;
    text-decoration: underline;

    @include hover-focus {
      color: #ff0000;
    }
  }
  
  h1 {
    font-size: 180%;
    color: $color-gray;
  }

  h4 {
    font-size: 195%;
  }

  .wp-block-image {
    padding: .7em 0;
  }
}

ul.blue-bullets {
  list-style: none;

  li {
    line-height: 1.5;

    &:before {
      content: "";
      position: absolute;
      background-color: $color-blue;
      width: 8px;
      height: 8px;
      margin-left: -20px;
      margin-top: 8px;
    }

    ul {
      list-style: none;
    }
  }
}

.text-blue {
  color: $color-blue;
}

.box-signup {
  background: rgba(255,255,255,.9);
  border-radius: 0;
  border: 5px solid #5425af;
  padding: 3%;
}

.trackmap-header {
  .heading-text {
    h2 {
      color: $color-blue;
      font-size: rem(40);
      padding: 20px 0;
    }
  }
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-20 {
  margin-top: rem(20) !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.text-pink {
  color: $color-pink;
}

.data-table {
  thead {
    th {
      border: 1px solid $color-gray;
      background: $color-gray;
      color: $color-white;
      padding: rem(15);
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        td {
          background: $color-skin-d;
        }
      }

      td {
        border: 1px solid $color-gray;
        padding: rem(15);
        background: $color-white;
        color: $color-black;
        text-align: left;
        font-size: 14px;
        line-height: 1.4;

        a {
          text-decoration: none;
        }
      }
    }
  }

  &.text-center {
    thead {
      th {
        text-align: center;
      }
    }

    tbody {
      td {
        text-align: center;
      }
    }
  }
}

.ama-release-table {
  table {
    margin: 0 auto;
    max-width: 960px;
    width: auto;
  }  
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.adv-container {
  padding: rem(50) 15px;

  &.adv-top {
    padding-bottom: 0;
  }

  &.adv-bottom {
    padding-top: 0;
  }

  > div {
    display: flex;
    justify-content: center;
  }
}
