.felds-riders-block {
  padding: rem(50) 0;
  padding-top: 0;
  overflow: hidden;

  .adv-container.adv-top{
    padding-bottom: rem(50);
  }

  .block-header {
    position: relative;
    // margin-bottom: rem(50);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(15) 0;

    @include tablet-down {
      display: block;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 200%;
      transform: skewX(-19deg);
      background: $color-gray;
      z-index: 1;
    }

    &:before {
      left: -5%;
    }

    &:after {
      right: -5%;
    }

    &__title {
      position: relative;
      z-index: 2;
      font-size: rem(88);
      font-family: $secondary-font;
      font-weight: 700;
      color: $color-blue;      
      text-transform: uppercase;
      padding: rem(30) rem(40);

      @include tablet-down {
        text-align: center;
      }

      @include phone-down {
        font-size: 48px;
      }
    }

    &__classes {
      flex: 0 0 16%;
      position: relative;
      z-index: 2;

      @include tablet-down {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: rem(40);
      }

      a {
        display: block;
        text-align: center;
        font-size: rem(32);
        line-height: 2;
        transform: skewX(-19deg);
        background: $color-white;
        color: $color-blue;
        position: relative;
        width: 100%;

        @include tablet-down {
          width: 40%;
        }

        @include phone-down {
          font-size: 21px;
        }

        span {
          display: block;
          transform: skewX(19deg);
          font-family: $secondary-font;
        }

        &.active {
          background: $color-blue;
          color: $color-white;
          right: -1.8rem;

          @include tablet-down {
            right: 0;
          }
        }

        & + a {
          margin-top: rem(15);

          @include tablet-down {
            margin-top: 0;
          }
        }
      }
    }
  }

  .block-content {
    display: flex;
    flex-wrap: wrap;

    @include tablet-down {
      display: block;
      padding: 0 2rem;
    }

    @include phone-down {
      padding: 0 1rem;
    }

    .item {
      flex: 0 0 23%;
      margin: 0 1% 2%;

      @include phone-down {
        margin-bottom: 2rem;
      }

      &-image {
        position: relative;
        height: 0;
        padding-bottom: 56%;
        border-bottom: rem(10) solid $color-blue;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-name {
        text-align: center;
        font-family: $secondary-font;
        font-size: rem(24);
        font-weight: 700;
        padding: rem(10) 0;
      }
    }
  }
}