#header {
  background: $color-gray;
  position: relative;
  z-index: 100;

  @include tablet-down {
    overflow: hidden;
  }

  .global-header {
    display: flex;
    justify-content: center;

    a {
      padding: 10px;
      display: block;
      position: relative;

      &:first-child {
        &:after {
          content: '';
          position: absolute;
          height: rem(4);
          width: 60%;
          background: $color-yellow;
          transform: skewX(-45deg) translateX(-50%);
          bottom: 0;
          left: 50%;
        }
      }

      img {
        height: 40px;
        width: auto;
        aspect-ratio: 4/1;
        object-fit: contain;

        @include phone-down {
          height: 20px;
        }
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include tablet-down {
      align-items: stretch;
    }

    .logo {
      width: 280px;

      @include tablet-down {
        width: 190px;
        padding: 15px 0;
      }
    }

    .nav-wrapper {
      width: calc(100% - 320px);

      @include tablet-down {
        display: none;
      }

      .main-menu {
        background: $color-black;
        padding: 0 40px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 10;

        &:before {
          content: '';
          position: absolute;
          left: -15px;
          top: 0;
          width: 30px;
          height: 100%;
          background: $color-black;
          transform: skewX(-19deg);
        }

        .menu {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;

          > li {
            margin: 0 10px;
            position: relative;

            > a {
              display: block;
              padding: 25px 0;
              text-transform: uppercase;
              font-family: $secondary-font;
              font-weight: 700;
              color: $color-blue;
              font-size: 17px;
              position: relative;
              letter-spacing: -0.045em;
            }

            ul {
              display: none;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              width: 14rem;
              padding: 1rem 0;
              margin: 0;
              background: $color-gray;
              list-style: none;

              li {
                padding: 8px 0;
                text-align: center;

                a {
                  font-family: $secondary-font;
                  font-weight: 700;
                  color: $color-white;
                  font-size: 17px;
                  text-transform: uppercase;
                  transition: none;
                  letter-spacing: -0.045em;

                  &:hover {
                    border-bottom: 2px solid $color-blue;
                  }
                }
              }
            }

            &:hover {
              > a {
                &:before {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  background: $color-blue;
                  bottom: 0;
                  left: 0;
                }
              }

              ul {
                display: block;
              }
            }
          }
        }

        .link-search {
          padding: 25px 0;
          color: $color-blue;
          font-size: 17px;
          margin: 0 30px 0 10px;
        }
      }

      .secondary-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;

          li {
            padding: 0 8px;

            a {
              display: block;
              padding: 12px 0;
              text-transform: uppercase;
              font-family: $secondary-font;
              font-weight: 700;
              color: $color-white;
              font-size: 16px;
            }
          }
        }

        .socials {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;

          li {
            padding: 0 10px;

            a {
              font-size: 17px;
              color: $color-white;
            }
          }
        }
      }
    }
  }

  .hamburger {
		display: none;
    position: relative;

    &:before {
      content: '';
      background: $color-black;
      transform: skew(-19deg);
      width: 100px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -15px;
    }

		.hamburger-box {
			width: 40px;
      position: relative;
		}

		.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
			background: $color-blue;
			width: 40px;
			height: rem(3);
			border-radius: 0;
		}

		@include tablet-down {
			display: block;
		}
	}
}

.mobile-navs {
  position: fixed;
  z-index: 90;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background: $color-black;
  overflow-y: auto;
  transform: translateY(-100%);
  transition: all 250ms linear;

  &__inner {
    padding-bottom: 120px;
  }

  .mobile-search {
    position: relative;
    padding: 15px;
    border-bottom: 2px solid #222428;

    input {
      width: 100%;
      padding: 10px 10px 10px 50px;
      background: $color-white;
      box-sizing: border-box;
    }

    button {
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: $color-blue;
      font-size: 18px;
      background: none;
      border: none;
    }
  }

  nav {
    margin: 0;

    > ul {
      margin: 0;
      padding: 0;

      > li {
        text-align: center;
        padding: 30px 0;
        border-bottom: 2px solid $color-gray;

        > a {
          color: $color-blue;
          font-size: 30px;
          font-family: $secondary-font;
          font-weight: 700;
          text-transform: uppercase;
          position: relative;

          i {
            margin-left: 15px;
            font-size: 24px;
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        ul {
          display: none;
          margin: 0;
          padding: 25px 0 0 0;

          li {
            text-align: center;
            padding: 15px 0;

            a {
              color: $color-blue;
              font-size: 30px;
              font-family: $secondary-font;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
        }

        &.open {
          ul {
            display: block;
          }
        }
      }
    }
  }
}

.mobile-link-tickets {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 10px 0;
  font-size: 21px;
  font-family: $primary-font;
  font-style: italic;
  text-align: center;
  letter-spacing: 0;
  background: $color-blue;
  color: $color-white;
  text-transform: uppercase;
  font-weight: 700;
  display: none;

  @include tablet-down {
    display: block;
  }
}

.menu-opened {
  overflow: hidden;

  body {
    overflow: hidden;
  }

  .hamburger {
    .hamburger-inner {
      height: 0 !important;

      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
        top: 50%;
        left: 50%;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
        top: 50%;
        left: 50%;
      }
    }
  }

  .mobile-navs {
    transform: translateY(0);
  }  
}