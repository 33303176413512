.felds-leaderboard-block {
  #live-timing-widget{
    padding: 40px 0;
    display: none;

    #live_timing {
      position: relative;
    }
   
    #live_timing.loadings::after {
      content: 'Loading live timing...';
      display: block;
      background: $color-blue;
      color: $color-white;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 5px;
      transform: translate(-50%, -50%);
    }
   
    #live_timing > iframe {
      overflow: auto;
      min-height: 500px;
      width: 100%;
      border: 0;
    }
  }
}