#footer {
  background: $color-gray;
  color: $color-white;

  .footer-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 60px 60px;

    @include tablet-down {
      display: block;
      text-align: center;
    }

    @include phone-down {
      padding: 50px 2rem;
    }

    &:before {
      content: '';
      background: $color-black;
      position: absolute;
      top: 0;
      left: 20%;
      width: 10px;
      height: 100%;
      transform: skewX(-19deg);

      @include tablet-down {
        display: none;
      }
    }

    .footer-left {
      flex: 0 0 20%;

      .logo {
        img {
          width: 180px;

          @include tablet-down {
            display: inline-block;
            width: auto;
            height: 70px;
          }
        }
      }
    }

    .footer-middle {
      flex: 0 0 50%;

      @include tablet-down {
        padding: 50px 0;
      }

      .footer-nav {
        &__title {
          font-family: $secondary-font;
          font-weight: 700;
          text-transform: uppercase;
          padding: 0 0 20px 0;

          @include phone-down {
            font-size: 16px;
          }

          span {
            color: $color-blue;
          }
        }

        &__links {
          .menu {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;

            @include tablet-down {
              display: block;
            }
    
            li {
              @include tablet-down {
                display: inline;
              }

              & + li {
                border-left: 2px solid $color-blue;  
                padding-left: 12px;
                margin-left: 12px;
              }

              a {
                font-size: 14px;
                line-height: 1;
                color: $color-white;

                @include tablet-down {
                  font-size: 13px;
                  line-height: 2;
                }

                &:hover {
                  border-bottom: 2px solid $color-blue;
                }
              }              
            }
          }
        }
      }      
    }

    .footer-right {
      flex: 0 0 20%;
      text-align: right;

      .menu {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: right;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @include tablet-down {
          display: block;
          text-align: center;
        }

        li {
          margin: 1px 5px;

          @include tablet-down {
            display: inline-block;
          }
          
          a {
            font-size: 13px;
            line-height: 1.3;
            color: $color-white;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .footer-socials {
        padding: 20px 0;

        @include tablet-down {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .socials {
          padding: 0;
          margin: 0 0 5px 0;
          list-style: none;
          display: flex;
          justify-content: flex-end;
  
          li {
            padding: 0 10px;
  
            a {
              font-size: 20px;
            }
          }
        }
  
        img {
          height: 30px;
          display: inline-block;
        }
      }

      .copyright {
        font-size: 10px;

        @include tablet-down {
          text-align: center;
          padding-bottom: 30px;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
}