html {
	background: $color-skin;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 2.4vw;
	}

	@include phone-down {
		font-size: 14.4px;
	}
}

body {
	background: $color-skin;
	color: $color-black;
	line-height: 1.25;

	&.mobile-menu-opened {
		overflow-y: hidden;
	}
}

#main {
}

* {
	outline: none;
}

a {
	color: inherit;
	transition: none;
}

.pos-relative {
	position: relative;
}

.btn {
	padding: rem(10) rem(15);
	font-size: rem(18);
	font-weight: 700;
	font-family: $secondary-font;
	letter-spacing: rem(-0.7);
	display: inline-block;
	transition: all 250ms linear;
	text-transform: uppercase;	

	> a {
		display: block;
		padding: rem(10) rem(15);
		color: inherit !important;
	}

	&.btn-blue {
		background: $color-blue;
		color: $color-white !important;
		border: rem(2) solid $color-blue;

		&:hover {
			background: $color-blue;
			border-color: $color-blue;
			color: $color-white !important;
		}
	}

	&.btn-blue-o {
		background: none;
		color: $color-white !important;
		border: rem(2) solid $color-blue;

		&:hover {
			background: $color-blue;
			color: $color-white !important;
		}
	}

	&.btn-white {
		background: $color-white;
		color: $color-blue !important;
		border: rem(2) solid $color-white;

		&:hover {
			opacity: 0.9;
			color: $color-blue !important;
		}
	}

	&.btn-white-o {
		background: none;
		color: $color-white !important;
		border: rem(2) solid $color-white;

		&:hover {
			background: $color-white;
			color: $color-blue !important;
		}
	}
}

.youtube-video {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
	
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.desktop-only {
	@include tablet-down {
		display: none;
	}
}

.mobile-only {
	display: none;
	
	@include tablet-down {
		display: block;
	}
}

.text-red {
	color: red;
}

.select2-container {
  .select2-selection {
    height: rem(48);
    padding: 0 rem(30) 0 0;
    border-radius: 0;
    border-color: $color-black;

    .select2-selection__rendered {
      line-height: rem(46);
      font-size: rem(16);
      padding-left: rem(15);
      color: $color-gray;
    }

    .select2-selection__arrow {
      height: rem(46);
      width: rem(30);

      b {
        display: none;
      }

      &:before {
        content: "\f107";
        font-family: 'Font Awesome\ 6 Pro';
        font-weight: 400;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &[aria-expanded="true"] {
      .select2-selection__arrow {
        &:before {
          content: "\f106";
        }
      }
    }
  }

  .select2-dropdown {
    border-radius: 0;
    border-color: $color-black;

    .select2-search {
      display: none;
    }
		
    .select2-results {
      .select2-results__options {
        .select2-results__option {
          font-size: rem(16);
          padding: rem(5) rem(15);
        }
      }
    }
  }
}

.lg-container {
	.lg-outer {
		.lg-item:not(.lg-start-end-progress) {
			background: url(../img/loader.svg) 50% 50% no-repeat;
		}

		.lg-next,
		.lg-prev {
			background: $color-black;

			&:before {
				content: '';
				display: none;				
			}

			&:after {
				display: none;
			}
		}

		.lg-toolbar {
			background: rgba(0, 0, 0, .5);
		}
	}
}

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&:before {
		display: block;
		content: '';
		width: rem(40);
		height: rem(40);
		background-color: $color-blue;
		transition: all .2s ease-in-out;
		animation: sk-rotateplane 1.2s infinite ease-in-out;
	}
}

@keyframes sk-rotateplane {
	0% {
			-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
			transform: perspective(120px) rotateX(0) rotateY(0)
	}

	50% {
			-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
			transform: perspective(120px) rotateX(-180.1deg) rotateY(0)
	}

	100% {
			-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
			transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
	}
}


#where-to-watch{
	background-color: #222428;
	padding: 10px;
	text-align: center;
	width: 100%;
	.us,.non-us{
		display: none;
	}
	&.us-content{
		.us{
			display: block;
		}
	}
	&.non-us-content{
		.non-us{
			display: block;
		}
	}
	.logos{
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: center;
		img{
			height: 20px;
		}
	}
	.info{
		margin: 10px 0;
		font-size: 16px;
		p{
			color: #ffffff;
			font-family: Dincond,sans-serif;
			margin: 0;
		}
	}
	.cta {
		a{
			font-family: Dincond,sans-serif;
			color: #0072ce;
			text-transform: uppercase; 
			font-size: 18px;
		} 
	}
}




@media screen and (min-width: 992px){
	.felds-latest-posts-block .block-content{
		position: relative;
		#where-to-watch{
			padding: 15px;
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 999;
			max-width: 210px;
			text-align: right;
			background-color: #222428f0;
			.logos{
				justify-content: flex-end;
				img{
					height: 25px;
				}
			}
		}
	}

}

main#main{
	margin-top: 0 !important;
}

.event-broadcast{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	padding: 0 30px;
	img{
		height: 20px;
		width: 100%;
		object-fit: contain;
		object-position: center;
		flex: 1;
		max-width: max-content;
	}

	&.content-us{
		img{
			&.int{
				display: none !important;
			}
		}
	}

	&.content-int{
		.broadcast-logo{
			&.us{
				display: none !important;
			}
		}
	}

}