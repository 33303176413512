.felds-team-dunlop-block {
  padding: rem(50) 0;

  .block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet-down {
      display: block;
    }

    &__image {
      flex: 0 0 48.5%;

      @include phone-down {
        margin-bottom: 20px;
      }
    }

    &__content {
      flex: 0 0 48.5%;

      &-title {

      }

      &-desc {
        a {
          color: $color-blue;
        }
      }

      &-action {
        padding: rem(10) 0 0 0;

        a {
          font-size: rem(24);
          padding: 5px 10px;
        }
      }      
    }
  }

  .block-content {
    padding: rem(80) 0;

    .member {
      display: flex;
      justify-content: space-between;

      @include tablet-down {
        align-items: center;
      }

      @include phone-down {
        display: block;
      }

      & + .member {
        margin-top: rem(50);
      }

      &-summary {
        flex: 0 0 48.5%;

        &__title {
          h1 {
            
          }
        }

        &__instagram {
          font-size: rem(24);
          font-family: $secondary-font;

          a {
            color: -webkit-link;
            text-decoration: underline;
            font-weight: 700;
        
            @include hover-focus {
              color: #ff0000;
            }
          }
        }

        &__desc {
          font-size: rem(14.67);
          font-family: Calibri;
          line-height: 1.3;
        }

        &__action {
          a {
            padding: 5px 10px;
            font-size: rem(16);
          }
        }
      }

      &-youtube {
        flex: 0 0 48.5%;

        @include phone-down {
          margin-top: 20px;
        }
      }
    }
  }

  .block-footer {
    text-align: center;
    padding: 0 8%;

    &__desc {
      line-height: 1.5;

      a {
        color: $color-blue;
      }
    }

    &__action {
      position: relative;
      padding: 0 0 rem(30);

      a {
        font-size: rem(18);
        padding: 5px 10px;

        &:before {
          content: '';
          position: absolute;
          height: 1px;
          background: $color-blue;
          width: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all 250ms linear;
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}